<script setup>

</script>

<template>
  <MotoHeader />
  <Container no-paddings>
    <BannerMain :bannerName="'moto-gora-1440x300'" />
  </Container>
  <slot />
  <AppFooter />
  <Blend />
</template>
