<script setup>
const {blogCategories, fetchBlogCategories} = useBlogCategories();
await fetchBlogCategories();
const {breakpoint} = useBreakpoints();
const showSearchInput = ref(false);
const smallishLogo = ref(false);

const emits = defineEmits(['hamburgerClick']);
const clickMobileMenu = () => {
  emits('hamburgerClick');
};
const clickSearchIcon = () => {
  showSearchInput.value = !showSearchInput.value;
  smallishLogo.value = showSearchInput.value;
};

onMounted(() => {
  if (breakpoint.value !== 'xl' && breakpoint.value !== '2xl' && breakpoint.value !== 'lg') {
    showSearchInput.value = false;
  } else {
    showSearchInput.value = true;
  }
});
</script>

<template>
  <div class="bg-white">
    <div class="flex justify-between items-center border-b border-b-gray-200 py-2 gap-2">
      <AppHeaderActionBar :custom-logo="true" no-button-call no-full>
        <template #custom-logo>
          <NuxtLink :to="'/moto'">
            <div class="flex transition-all w-28" :class="{'!w-20':smallishLogo}">
              <VectorMotoLogo />
            </div>
          </NuxtLink>
          <InputSearch @click.prevent="e => e.stopPropagation()" :showSearchInput="showSearchInput" />

        </template>

      </AppHeaderActionBar>
      <div class="flex gap-6 justify-end lg:justify-between xl:justify-normal">
        <div class="flex gap-6 align-center">
          <MenuItem v-for="category in blogCategories" :key="category.id" :href="`/moto/kategoria/${category.slug}`" is-moto>
            {{ category.name }}
          </MenuItem>
          <div class="lg:hidden w-5 h-5" v-show="!showSearchInput" @click="clickSearchIcon">
            <IconBrowse />
          </div>
          <div class="xl:hidden w-5 h-5" @click="clickMobileMenu">
            <IconHamburger />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
