export const useBlogCategories = () => {
    const blogCategories = useState('blogCategories', () => null);
  
    const fetchBlogCategories = async () => {
      if (!blogCategories.value) {
        const backend = useBackend();
        const { data } = await backend.getBlogCategories();
        const displayedOrderById = [1, 2, 3, 4, 5, 12, 6, 7, 8, 10];
        const orderedData = displayedOrderById
        .map(id => data.find(item => item.id === id))
        .filter(Boolean); 
        blogCategories.value = orderedData;
      }
    };
  
    return {
        blogCategories,
        fetchBlogCategories,
    };
  };
  