<template>
    
<svg width="113" height="50" viewBox="0 0 113 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group">
<path id="Vector" d="M5.26932 0.234177L11.2412 13.5831L17.096 0.234177H21.4286L22.1312 21.897H18.0328L17.5644 7.14285L12.4122 19.0866H9.71898L4.44965 7.25994L3.98127 21.897H0L0.819673 0.234177H5.26932Z" fill="#483A4F"/>
<path id="Vector_2" d="M27.7539 11.1241C27.7539 4.56675 32.6719 7.62939e-06 38.9951 7.62939e-06C45.3183 7.62939e-06 50.1193 4.68385 50.1193 11.1241C50.1193 17.5644 45.3183 22.3654 38.878 22.3654C32.5548 22.2483 27.7539 17.5644 27.7539 11.1241ZM45.6696 11.1241C45.6696 6.79158 42.8593 3.74708 38.878 3.74708C35.0139 3.74708 32.0865 6.79158 32.0865 11.1241C32.0865 15.4567 34.8968 18.5012 38.878 18.5012C42.8593 18.5012 45.6696 15.3396 45.6696 11.1241Z" fill="#483A4F"/>
<path id="Vector_3" d="M74.3554 3.98125H68.0322V21.897H63.6997V3.98125H57.3765V0.234177H74.3554V3.98125Z" fill="#483A4F"/>
<path id="Vector_4" d="M81.4976 11.1241C81.4976 4.56675 86.4156 7.62939e-06 92.7388 7.62939e-06C99.062 7.62939e-06 103.863 4.68385 103.863 11.1241C103.863 17.5644 99.062 22.3654 92.6217 22.3654C86.2985 22.2483 81.4976 17.5644 81.4976 11.1241ZM99.5304 11.1241C99.5304 6.79158 96.72 3.74708 92.7388 3.74708C88.8746 3.74708 85.9472 6.79158 85.9472 11.1241C85.9472 15.4567 88.7575 18.5012 92.7388 18.5012C96.72 18.5012 99.5304 15.3396 99.5304 11.1241Z" fill="#483A4F"/>
<g id="Group_2">
<path id="Vector_5" d="M56.0905 44.7307L59.2521 36.7682H60.423L56.6759 45.9017H55.505L51.875 36.7682H53.046L56.0905 44.7307Z" fill="black"/>
<path id="Vector_6" d="M66.0447 46.1358C63.4686 46.1358 61.3608 44.4964 61.3608 41.4519C61.3608 38.5245 63.3515 36.651 65.8105 36.651C68.3866 36.651 70.026 38.6416 70.026 41.2177C70.026 41.4519 70.026 41.6861 70.026 41.8032H62.4147C62.5318 43.9109 63.8199 45.199 66.0447 45.199C67.0985 45.199 67.9182 44.8477 68.855 44.3793L69.3234 45.0819C68.5037 45.7845 67.3327 46.1358 66.0447 46.1358ZM69.0892 40.8664C69.0892 39.2271 67.9182 37.5877 65.9276 37.5877C64.0541 37.5877 62.6489 38.9929 62.5318 40.8664H69.0892Z" fill="black"/>
<path id="Vector_7" d="M79.5125 46.0187V40.8665C79.5125 38.993 78.6928 37.7049 76.8193 37.7049C74.9457 37.7049 73.4235 38.993 73.4235 40.7494V46.0187H72.3696V32.5527L73.4235 32.3185V38.2904C74.2432 37.1194 75.4141 36.5339 76.8193 36.5339C79.1612 36.5339 80.5663 37.9391 80.5663 40.281V45.7845H79.5125V46.0187Z" fill="black"/>
<path id="Vector_8" d="M83.021 33.8407C83.021 33.3723 83.3723 33.021 83.8407 33.021C84.309 33.021 84.6603 33.3723 84.6603 33.8407C84.6603 34.3091 84.309 34.6604 83.8407 34.6604C83.3723 34.6604 83.021 34.3091 83.021 33.8407ZM83.3723 36.8852H84.4261V46.0187H83.3723V36.8852Z" fill="black"/>
<path id="Vector_9" d="M89.581 45.1989C90.6349 45.1989 92.04 44.8476 92.04 43.5595C92.04 41.2176 86.8878 42.3886 86.8878 39.1099C86.8878 37.3535 88.4101 36.5338 90.0494 36.5338C91.1033 36.5338 92.04 36.8851 92.8597 37.3535L92.3913 38.056C91.8059 37.7047 90.9862 37.4706 90.1665 37.4706C89.1126 37.4706 87.9417 37.8218 87.9417 38.9928C87.9417 41.4518 93.0939 40.1638 93.0939 43.4425C93.0939 45.1989 91.5717 46.0186 89.6981 46.0186C88.293 46.0186 87.3562 45.5502 86.4194 44.9647L87.0049 44.2621C87.8246 44.8476 88.6443 45.1989 89.581 45.1989Z" fill="black"/>
<path id="Vector_10" d="M95.0845 45.3161C95.0845 44.8477 95.4358 44.4964 95.9042 44.4964C96.3725 44.4964 96.7238 44.8477 96.7238 45.3161C96.7238 45.7845 96.3725 46.1358 95.9042 46.1358C95.4358 46.1358 95.0845 45.7845 95.0845 45.3161Z" fill="black"/>
<path id="Vector_11" d="M100.468 38.4074C101.288 37.2365 102.459 36.651 103.864 36.651C105.971 36.651 108.313 38.0561 108.313 41.3348C108.313 44.6135 106.089 46.1358 103.864 46.1358C102.459 46.1358 101.288 45.5503 100.468 44.3793V49.7657L99.4141 49.9999V36.8852H100.351L100.468 38.4074ZM103.864 37.5877C101.873 37.5877 100.468 39.11 100.468 41.4519C100.468 43.7938 101.99 45.199 103.864 45.199C105.971 45.199 107.259 43.5596 107.259 41.3348C107.142 39.11 105.854 37.5877 103.864 37.5877Z" fill="black"/>
<path id="Vector_12" d="M110.541 32.5527L111.594 32.3185V43.5597C111.594 44.4965 111.711 45.082 112.18 45.1991L112.063 46.0187C111.243 46.0187 110.541 45.4332 110.541 44.1452V32.5527Z" fill="black"/>
</g>
<g id="Group_3">
<path id="Vector_13" d="M107.964 20.2575C107.964 18.9694 109.018 17.9156 110.306 17.9156C111.594 17.9156 112.648 18.9694 112.648 20.2575C112.648 21.5455 111.594 22.5994 110.306 22.5994C108.901 22.5994 107.964 21.5455 107.964 20.2575Z" fill="#DB1A21"/>
</g>
</g>
</svg>

</template>