<script setup>
const props = defineProps({
  placeholder: {
    type: String,
    default: 'Wyszukaj...',
  },
  showSearchInput: {
    type: Boolean,
    default: true,
  }
});

const inputValue = ref('');
const isFocused = ref(false);
const route = useRoute();

function onBlur() {
  if (!inputValue.value) {
    isFocused.value = false;
  }
}

function clearInput() {
  inputValue.value = '';
  isFocused.value = false;
}

function onSubmit(event) {
  event.preventDefault();
  if (inputValue.value) {
    navigateTo({
      name: 'search-page',
      query: { search: inputValue.value }
    });
  }
}

onMounted(() => {
  if(route.query?.search) {
    inputValue.value = route.query.search;
  }
})
</script>

<template>
  <form @submit="onSubmit" class="relative ml-4 flex items-center" v-if="showSearchInput">
    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
      <IconBrowse class="text-gray-500" />
    </div>
    <input
      type="search"
      v-model="inputValue"
      @focus="isFocused = true"
      @blur="onBlur"
      class="w-full pl-10 pr-3 py-2 border-b border-gray-300 focus:outline-none focus:border-azure transition-all placeholder:font-extralight rounded-none bg-transparent placeholder-transparent"
    />
    <label
      :class="[
        'absolute transition-all duration-200 transform pointer-events-none',
        {
          'top-[-12px] left-3 text-xs text-azure': isFocused || inputValue,
          'top-1/2 left-10 text-base text-gray-500 -translate-y-1/2': !(isFocused || inputValue)
        }
      ]"
    >
      {{ placeholder }}
    </label>
    <div v-if="inputValue" @click="clearInput" class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer text-gray-500">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
  </form>
</template>
